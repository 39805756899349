<template>
  <collection-layout-page
    :collectionId="collectionId"
    :stateCache="stateCache" />
</template>
<script>
import {mapGetters} from 'vuex'
import CollectionLayoutPage from '@/components/collections/layout/collection-layout-page.vue'

export default {
  props: ['collectionId'],
  data: () => ({
    stateCache: null
  }),
  components: {
    CollectionLayoutPage
  },
  created () {
    this.stateCache = this.getStateCache(`collection.${this.collectionId}.table.search`)
  },
  computed: {
    ...mapGetters(['getStateCache']),
  }
}
</script>
