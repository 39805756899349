<template>
  <div @drop.prevent="onFileDrop" @dragenter.prevent @dragover.prevent>
    <slot v-bind:on="triggerFileDialog">
      {{translate('Drag files here or click to select')}}
    </slot>
    <div style="display: none">
      <input
        type="file"
        ref="hiddenFileInput"
        @change="onFileChange" />
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
export default {
  props: ['loading'],
  computed: {
    ...mapGetters(['translate'])
  },
  methods: {
    triggerFileDialog () {
      this.$refs.hiddenFileInput.click()
    },
    onFileDrop (ev) {
      // ev.preventDefault()
      let items = ev.dataTransfer.items || []
      let file = null
      for (let i = 0; i < items.length; ++i) {
        if (items[i].kind === 'file') {
          file = file || items[i].getAsFile()
        }
      }
      this.$emit('file', file)
    },
    onFileChange () {
      let files = this.$refs.hiddenFileInput.files
      let file = null
      for (let i = 0; i < files.length; ++i) {
        file = file || files[i]
      }
      this.$refs.hiddenFileInput.value = ''
      this.$emit('file', file)
    }
  }
}
</script>
