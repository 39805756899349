<template>
  <v-dialog
    :value="dialog"
    hide-overlay
    persistent
    @keydown.esc="$emit('close-dialog')"
  >
    <v-card>
      <v-card-title>{{translate('Excel import')}}</v-card-title>
      <v-card-text>
        <v-stepper v-model="wizardStep">
          <v-stepper-header>
            <v-stepper-step editable step="1">{{translate('Choose file')}}</v-stepper-step>
            <v-stepper-step editable step="2" :rules="[() => parsedHeaders.length > 0]">{{translate('Map columns')}}</v-stepper-step>
            <v-stepper-step editable step="3" :rules="[() => updateSet.length > 0]">{{translate('Set update mode')}}</v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <dropzone :loading="loading" @file="bindFile">
                <template v-slot:default="{on}">
                  <v-alert outlined type="info" prominent @click="on" style="cursor:pointer">
                    <h3 class="headline">
                      {{translate('Drag files here or click to select')}}
                    </h3>
                    <v-divider v-if="file" />
                    <v-simple-table v-if="file">
                      <tr>
                        <th>{{translate('Name')}}</th>
                        <td>{{file.name}}</td>
                      </tr>
                      <tr>
                        <th>{{translate('Size')}}</th>
                        <td>{{formatFileSize(file.size)}}</td>
                      </tr>
                      <tr>
                        <th>{{translate('Type')}}</th>
                        <td>{{file.type}}</td>
                      </tr>
                    </v-simple-table>
                  </v-alert>
                </template>
              </dropzone>
            </v-stepper-content>
            <v-stepper-content step="2">
              <v-data-table
                v-if="!loading && parsedHeaders.length"
                :headers="parsedHeaders"
                :items="parsedRows"
                :items-per-page="5">
                <template v-slot:header="{ props: { headers } }">
                  <thead>
                    <tr :key="'mappings'">
                      <th v-for="header in headers" :key="header.text">
                        <v-autocomplete
                          clearable
                          dense
                          :items="attributeMappings"
                          :label="translate('Mapping')"
                          item-text="label"
                          item-value="path"
                          v-model="selectedMappings[header.value]"
                        >
                        </v-autocomplete>
                      </th>
                    </tr>
                  </thead>
                </template>
              </v-data-table>
              <!--
              <pre><code>{{JSON.stringify(updateSet, null, 2)}}</code></pre>
              -->
            </v-stepper-content>
            <v-stepper-content step="3">
              <v-card>
                <v-row>
                  <v-col>
                    <v-card-title>{{translate('Source of truth')}}</v-card-title>
                    <v-card-text>
                      <v-radio-group v-model="overwrite">
                        <v-radio
                          key="um1"
                          :label="translate('Always overwrite with values from file')"
                          :value="true" />
                        <v-radio
                          key="um2"
                          :label="translate('Update missing values only')"
                          :value="false" />
                      </v-radio-group>
                    </v-card-text>
                  </v-col>
                  <v-col>
                    <v-card-title>{{translate('Restrictions')}}</v-card-title>
                    <v-card-text>
                      <v-radio-group v-model="restrict">
                        <v-radio
                          key="r3"
                          :label="translate('Update existing or create new records if missing')"
                          value="all" />
                        <v-radio
                          key="r2"
                          :label="translate('Skip existing records. Only append new records')"
                          value="new" />
                        <v-radio
                          key="r1"
                          :label="translate('Only update existing records. Never create new records')"
                          value="existing" />
                      </v-radio-group>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="$emit('close-dialog')">
            {{translate('Cancel')}}
        </v-btn>
        <v-btn color="primary" :disabled="!(updateSet && updateSet.length)" @click="upload">
            {{translate('Upload')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {mapGetters} from 'vuex'
import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'
import set from 'lodash/set'
import Dropzone from '@/components/dropzone.vue'
import parseDOMFileAsExcel from '@/lib/parse-dom-file-as-excel'
import filesize from 'filesize'
import pupa from 'pupa'
/**
  * @param schema Collection schema
  * @param items array of source objects (from excel)
  * @param mappings map of [source poperty] -> [dest path]
  * @keys array of restriction keys
  */
function createUpdateSet ({schema, items, mappings, keys}) {
  if (!(schema && items && mappings)) {
    return []
  }
  let m = Object.entries(mappings || {})
    .map(([source, dest]) => ({source, dest}))
    .filter(({source, dest}) => source && dest)

  let {key} = schema
  let hasKeyMapping = m.find(({dest}) => dest === key)
  let hasNonKeyMapping = m.find(({dest}) => dest !== key)

  const toKey = v => isNull(v) || isUndefined(v) ? '' : v.toString()
  let data = (hasKeyMapping && hasNonKeyMapping)
    ? items.map(item => m.reduce((o, {source, dest}) => set(o, dest, dest === key ? toKey(item[source]) : item[source]), {}))
    : []

  if (keys && keys.length) {
    let s = new Set(keys)
    data = data.filter(o => s.has(o[key]))
  }
  return data
}

export default {
  props: ['dialog', 'collectionSchema', 'collectionKeys'],
  data: () => ({
    file: null,
    parsing: null,
    wizardStep: 1,
    overwrite: false,
    restrict: 'existing',
    loading: false,
    selectedMappings: null
  }),
  components: {
    Dropzone
  },
  watch: {
    dialog (v) {
      if (!v) {
        this.file = null
        this.parsing = null
        this.wizardStep = 1
        this.loading = false
        this.selectedMappings = null
        this.overwrite = false
        this.restrict = 'existing'
      }
    }
  },
  computed: {
    ...mapGetters(['api', 'collectionsConfig', 'notifications', 'markets', 'translate']),

    parsedHeaders () {
      let {headers = []} = this.parsing || {}
      return headers.map(name => ({
        text: name,
        sortable: false,
        value: name,
        mapping: null
      }))
    },
    parsedRows () {
      let {items = []} = this.parsing || {}
      return items
    },

    attributeMappings () {
      let {key} = this.collectionSchema || {}

      const expand = ({name, attribute, label}) => name === key
      ? [({
          name,
          label: `${label} (${this.translate('key')})`,
          path: name,
          key: true
        })]
      : attribute.multimarket
      ? (this.markets || []).map(({description, marketId}) => ({
        name,
        label: `${label} (${description})`,
        path: `${name}.${marketId}`,
        multimarket: true
      }))
      : [{
        name,
        label,
        path: name
      }]

      let collectionAttributes = this.collectionsConfig.mapCollectionAttributes(this.collectionSchema)
      let keyCollectionAttributes = collectionAttributes.filter(({attribute: {name}}) => name === key)
      let nonKeyCollectionAttributes = collectionAttributes.filter(({attribute: {name}}) => name !== key)

      // First keys, the the rest
      return keyCollectionAttributes.concat(nonKeyCollectionAttributes)
        .map(expand)
        .reduce((a, a2) => a.concat(a2), [])
    },

    updateSet () {
      let {
        collectionSchema: schema,
        parsing,
        selectedMappings: mappings,
        collectionKeys: keys
      } = this
      let {items} = parsing || {}
      return createUpdateSet({schema, items, mappings, keys})
    }
  },

  methods: {
    async bindFile (file) {
      if (!file) {
        return
      }
      try {
        this.loading = true
        this.file = file
        let parsing = await parseDOMFileAsExcel(file)
        if (parsing) {
          this.parsing = parsing
          this.selectedMappings = {}
        }
        this.wizardStep = 2
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },
    formatFileSize (size) {
      return filesize(size)
    },
    async upload () {
      let {collectionId} = this.collectionSchema
      let {overwrite, restrict, updateSet: items} = this

      let updated = await this.api.collections.updateCollection({
        update: {
          collectionId,
          overwrite,
          restrict,
          items
        }
      })

      this.notifications.emit('notification-message', {
        type: 'success',
        message: pupa(this.translate('Updated {updateCount} objects from a set of {sourceCount} rows'), {
          updateCount: updated.length,
          sourceCount: items.length})
      })
      this.$emit('uploaded')
    }
  }
}
</script>
