import XLSX from 'xlsx'

async function parseDOMFileAsExcel (file) {
  const eventToBinary = (e) => {
    // convert loaded data to binary as in https://github.com/SheetJS/sheetjs/blob/20212e1cc222b047d7414d21428c611508c33a67/demos/vue/SheetJS-vue.js#L30
    let bytes = new Uint8Array(e.target.result)
    let l = bytes.byteLength;
    let binary = ''
    for (let i = 0; i < l; ++i) {
      binary += String.fromCharCode(bytes[i])
    }
    return binary
    // note that clever code like below for some reasn doesnt work
    // let binary = new Uint8Array(e.target.result).map(byte => String.fromCharCode(byte)).reduce((b, c) => b + c, '')
  }
  const parseExcelFromEvent = (e) => {
    let binary = eventToBinary(e)
    let wb = XLSX.read(binary, {type: 'binary'})
    let ws = wb.Sheets[wb.SheetNames[0]]
    // let json = XLSX.utils.sheet_to_json(ws, {header: 1})
    let items = XLSX.utils.sheet_to_json(ws, {raw: true})

    // determine all headers i.e property names
    let headers = Array.from(items.reduce((headers, item) => Object.keys(item).reduce((headers, name) => headers.add(name), headers), new Set()))

    return {
      headers,
      items
    }
  }

  const asAsync = fn => new Promise(resolve => resolve(fn()))

  return new Promise((resolve, reject) => {
      let reader = new FileReader()
      reader.onerror = function () {
        reject(reader.error)
      }
      reader.onload = e => {
        return resolve(asAsync(() => parseExcelFromEvent(e)))
      }
      reader.readAsArrayBuffer(file)
  })
}

export default parseDOMFileAsExcel
