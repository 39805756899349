<template>
  <v-dialog
    :value="dialog"
    hide-overlay
    persistent
    @keydown.esc="$emit('close-dialog')">
    <v-card>
      <v-card-title>{{translate('Download')}}</v-card-title>
      <v-card-text>
        <v-simple-table
          v-sortable-table="'.drag-handle'"
          :items="mappings"
          item-key="collectionId"
          :disable-filtering="true"
          :disable-pagination="true"
          :hide-default-header="true"
          :hide-default-footer="true"
          :expand="false"
          fixed-header
          :height="mappings.length > 4 ? '60vh' : ''"
          @drag-sorted="dragSorted($event, mappings)"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th>{{translate('Attribute')}}</th>
                <th>{{translate('Label')}}</th>
                <!--<th>{{translate('Format')}}</th>-->
                <th>{{translate('Market')}}</th>
                <th style="width:1%"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="mapping in mappings" :key="mapping.id">
                <td class="drag-handle">
                  <v-icon>swap_vert</v-icon>
                  {{mapping.name}}
                </td>
                <td>
                  <v-text-field label="Display name" :placeholder="mapping.label" v-model="mapping.label" />
                </td>
                <td>
                  <v-radio-group v-model="mapping.marketId">
                    <v-radio
                      v-for="m in getMappingMarkets(mapping)"
                      :key="m.marketId"
                      :label="m.description"
                      :value="m.marketId"
                    ></v-radio>
                  </v-radio-group>
                </td>
                <td style="width:1%">
                  <v-btn icon @click="removeElement({items: mappings, item: mapping})">
                    <v-icon>delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <!--
        <v-card-text>
          <pre><code>{{JSON.stringify(requestBody, null, 2)}}</code></pre>
        </v-card-text>
        -->
      </v-card-text>
      <v-card-actions>
        <v-menu offset-x>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on">
              <v-icon>add</v-icon>&nbsp;{{translate('Add column')}}
            </v-btn>
          </template>
          <v-card style="max-height:80vh; overflow-y: scroll">
            <v-list>
              <v-list-item
                v-for="(item, index) in templateMappings"
                :key="index"
                @click="addMapping(item)">
                <v-list-item-title>{{ item.label }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
        <v-spacer></v-spacer>
        <v-btn color="primary" v-for="format in downloadFormats" @click="startDownload(format)" :key="format.format">
          <v-icon>{{format.icon}}</v-icon>&nbsp;{{translate(format.title)}}
        </v-btn>

        <v-btn @click="$emit('close-dialog')" key="cancel">
          {{translate('Cancel')}}
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {mapGetters} from 'vuex'
import axios from 'axios'
import SortableTable from '@/directives/sortable-table'

const formatsByAttributeType = {
  'html': [{
    label: 'Keep HTML',
    format: 'value|identity'
  }, {
    label: 'Convert to text',
    format: 'value|stripHtml'
  }]
}

const clone = obj => JSON.parse(JSON.stringify(obj))

export default {
  props: ['dialog', 'collectionId', 'collectionKeys', 'collectionSchema', 'loading', 'downloadName'],
  data: () => ({
    downloading: false,
    mappings: [],
    templateMappings: [],
    nextId: 0
  }),
  components: {
  },
  computed: {
    ...mapGetters(['collectionsConfig', 'translate', 'currentMarketId', 'markets']),
    key () {
      return `download-${this.collectionId}-${Object.values(this.keys || '').join('@')}`
    },
    requestBody () {
      return this.mappings.map(({attribute: {name}, label, marketId, format}) => ({name, label, marketId, format}))
    },
    downloadFormats () {
      const t = this.translate
      return [{
        title: t('EXCEL'),
        format: 'excel',
        ext: 'xlsx',
        icon: 'fas fa-file-excel'
      }, {
        title: t('CSV'),
        format: 'csv',
        ext: 'csv',
        icon: 'fas fa-file'
      }, {
        title: t('TSV'),
        format: 'tsv',
        ext: 'tsv',
        icon: 'fas fa-file'
      }]
    }
  },
  directives: {
    SortableTable
  },
  mounted() {
    this.mappings = this.collectionsConfig
      .mapCollectionAttributes(this.collectionSchema)
      .map(mapping => ({
        ...mapping,
        format: 'value|identity',
        marketId: this.currentMarketId,
        id: this.makeId()
      }))
    this.templateMappings = clone(this.mappings)
  },
  methods: {
    async startDownload (downloadFormat) {
      this.downloading = true
      axios({
        method: 'POST',
        url: `/api/v1/download/collection/${this.collectionId}/${downloadFormat.format}/${this.currentMarketId}`,
        responseType: 'blob',
        data: {
          keys: this.collectionKeys,
          attributes: this.requestBody
        }
      })
      .then(response => {
        // https://gist.github.com/javilobo8/097c30a233786be52070986d8cdb1743
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${this.downloadName}.${downloadFormat.ext}`)
        document.body.appendChild(link)
        link.click()
      })
      .then(() => this.downloading = false)
      .catch(() => this.downloading = false)
    },
    makeId () {
      return ++this.nextId
    },
    addMapping (mapping) {
      this.mappings.splice(0, 0, {
        ...clone(mapping),
        id: this.makeId()
      })
    },
    getMappingFormats ({attribute: {type}}) {
      return  formatsByAttributeType[type]
    },
    getMappingMarkets ({attribute: {multimarket}}) {
      return multimarket ? this.markets : null
    },
    /*
    dragSorted ({newIndex, oldIndex, items} = event) {
      items.splice(newIndex, 0, items.splice(oldIndex, 1)[0])
    },
    */
    dragSorted ({newIndex, oldIndex} = event, items) {
      items.splice(newIndex, 0, items.splice(oldIndex, 1)[0])
    },
    removeElement ({items, item}) {
      items.splice(items.indexOf(item), 1)
    }
  }
}
</script>
