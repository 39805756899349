<template>
  <layout-page
    :right-drawer="drawerActions.length"
    :extended="false"
    :page-description="pageDescription"
    >
    <template v-slot:toolbar-title>
      <v-toolbar-title>
        <slot name="title">
          {{collectionsConfig.getCollectionLabel(collectionId)}}
        </slot>
      </v-toolbar-title>
    </template>
    <template v-slot:toolbar-items>
      <v-toolbar-items v-if="!collection.loading">
        <download-collection-dialog
          :dialog="activeDialog == 'download-file'"
          @close-dialog="activeDialog = null"
          :collectionId="collectionId"
          :collectionKeys="collectionKeys"
          :collectionSchema="collection.schema"
          :download-name="downloadName"
          :loading="collection.loading"
        />

        <upload-collection-dialog
          :dialog="activeDialog == 'upload-file'"
          @close-dialog="activeDialog = null"
          @uploaded="mutations = mutations + 1"
          :collectionSchema="collection.schema"
          :collectionKeys="collectionKeys"
          />

        <add-new-dialog
          :loading="collection.loading"
          :schema="collection.schema"
          />

        <v-btn
          text
          @click="activeDialog = 'download-file'">
          <v-icon>fa-download</v-icon> {{translate('Download')}}
        </v-btn>
        <v-btn
          text
          @click="activeDialog = 'upload-file'">
          <v-icon>fa-upload</v-icon> {{translate('Upload')}}
        </v-btn>

      </v-toolbar-items>
    </template>
    <template v-slot:drawer v-if="drawerActions.length && !collection.loading">
      <actions-list
        :actions="drawerActions"
        :actionParameters="actionParameters"
        />
    </template>

    <v-card>

      <v-card-title>
        <slot name="title">
          {{collectionsConfig.getCollectionLabel(collectionId)}}
        </slot>
      </v-card-title>
      <v-card-text>

        <slot name="before-table"></slot>

        <v-skeleton-loader v-if="collection.loading"
          type="table" />

        <collection-table v-if="!collection.loading"
          :loading="collection.loading"
          :items="collection.items"
          :schema="collection.schema"
          :channels="collection.channels"
          :relations="collection.relations"
          :reverse-relations="collection.reverseRelations"
          :suites="collection.suites"
          :sortable="true"
          :searchable="true"
          :state-cache="stateCache && stateCache.child('table')"
          >
          <template v-slot:edit-item="{item, schema}">
            <v-btn :to="{name: 'collection-item', params: {collectionId: schema.collectionId, itemKey: item.key}}" small icon color="primary">
              <v-icon>edit</v-icon>
            </v-btn>
          </template>
          <template v-slot:delete-item="{item, items, schema}">
            <confirm-button>
              <template v-slot:button="confirm">
                <v-btn text small icon color="warning" @click="confirm">
                  <v-icon>delete</v-icon>
                </v-btn>
              </template>
              <template v-slot:confirm="cancel">
                <v-item-group multiple>
                  <v-btn text small color="error" @click="deleteCollectionItem({item, items, schema})">
                    <v-icon>delete</v-icon> {{translate('Permanently delete this item?', 'delete.item')}}
                  </v-btn>
                  <v-btn text small icon @click="cancel">
                    <v-icon>cancel</v-icon>
                  </v-btn>
                </v-item-group>
              </template>
            </confirm-button>
          </template>
        </collection-table>
      </v-card-text>
    </v-card>
  </layout-page>
</template>
<script>
import {mapGetters} from 'vuex'
import get from 'object-get'
import LayoutPage from '@/components/layout/layout-page.vue'
import CollectionTable from '@/components/collections/collection-table.vue'
import ConfirmButton from '@/components/confirm-button.vue'
import AddNewDialog from './add-new-dialog.vue'
import ActionsList from '@/components/actions/actions-list'
//import DownloadCollectionDialogButton from '@/components/download/download-collection-dialog-button.vue'
import DownloadCollectionDialog from '@/components/download/download-collection-dialog.vue'
import UploadCollectionDialog from '@/components/upload/upload-collection-dialog.vue'

import keyBy from 'lodash/keyBy'

const makeDefaultDetails = () => ({
  loading: true,
  items: [],
  schema: {},
  channels: {},
  relations: {},
  reverseRelations: {},
  suites: {}
})

export default {
  props: ['collectionId', 'stateCache', 'collectionKeys', 'pageName', 'pageDescription'],
  data: () => ({
    mutations: 1,
    activeDialog: null
  }),
  components: {
    LayoutPage,
    CollectionTable,
    ConfirmButton,
    AddNewDialog,
    ActionsList,
    DownloadCollectionDialog,
    UploadCollectionDialog
  },
  computed: {
    ...mapGetters(['api', 'collectionsConfig', 'translate']),
    downloadName () {
      return this.pageName || this.collectionsConfig.getCollectionLabel(this.collectionId)
    },
    drawerActions () {
      return this.actions.filter(action => get(action, 'ui.important'))
    },
    actionParameters () {
      return {
        collectionId: this.collectionId
      }
    }
  },
  asyncComputed: {
    actions: {
      async get () {
        if (!this.collectionId) {
          return []
        }
        let allActions = await this.api.actions.getActions({cached: true})
        return allActions.filter(action => get(action, `scope.collection.${this.collectionId}`) === true)
      },
      default: []
    },
    collection: {
      async get () {
        if (!this.collectionId) {
          return {
            ...makeDefaultDetails()
          }
        }
        let loadAttributes = this.mutations && this.collectionsConfig.getTableAttributesForLoading(this.collectionId)
        let cd = await this.api.collections.getCollection({collectionId: this.collectionId, query: {
          attributes: loadAttributes && loadAttributes.join(',')
        }})

        if (Array.isArray(this.collectionKeys)) {
          let keyMap = keyBy(this.collectionKeys, key => key)
          return {
            ...cd,
            items: cd.items.filter(({key}) => keyMap[key] === key)
          }
        }
        return cd
      },
      default: {
        ...makeDefaultDetails()
      }
    }
  },
  methods: {
    async deleteCollectionItem ({item, items, schema}) {
      await this.api.collections.deleteCollection({
        update: {
          collectionId: schema.collectionId,
          items: [item.item]
        }
      })
      let index = items.indexOf(item)
      if (index >= 0) {
        items.splice(index, 1)
      }
    }
  }
}
</script>
