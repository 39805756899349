<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    max-width="600"
    >
    <template v-slot:activator="{on}">
      <v-btn text v-on="on">
        <v-icon>add</v-icon> {{translate('Add new', 'new')}}
      </v-btn>
    </template>

    <v-form v-model="validForm">
      <v-card>
        <v-card-title class="headline">
          {{translate('Add new', 'new')}}
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="key"
            :loading="loading || saving"
            :rules="validationRules.notEmpty()"
            :label="collectionsConfig.getCollectionAttributeLabel(schema.collectionId, schema.key)"/>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            :disabled="!validForm"
            text
            color="primary"
            @click="addNew"
            >
            <v-icon>save</v-icon> {{translate('Save', 'save')}}
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="dialog=false"
            >
            <v-icon>cancel</v-icon> {{translate('Cancel', 'cancel')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>

</template>
<script>
import {mapGetters} from 'vuex'
import {singular} from 'pluralize'
export default {
  props: ['schema', 'loading'],
  data: () => ({
    dialog: false,
    validForm: false,
    saving: false,
    key: ''
  }),
  mounted () {
    this.suggestKey()
  },
  computed: {
    ...mapGetters(['api', 'translate', 'collectionsConfig', 'validationRules'])
  },
  watch: {
    dialog (v) {
      if (!v) {
        this.key = null
      } else {
        this.suggestKey()
      }
    },
    schema (schema) {
      this.suggestKey(schema)
    }
  },
  methods: {
    suggestKey (schema) {
      let {collectionId} = schema || this.schema || {}
      if (collectionId && !this.key) {
        this.key = `${singular(collectionId)}-${Date.now().toString(36)}`
      }
    },
    async addNew () {
      this.saving = true
      await this.api.collections.updateCollection({
        update: {
          collectionId: this.schema.collectionId,
          items: [{
            [this.schema.key]: this.key
          }]
        }
      })
      this.saving = false
      // this.$emit('added-new')
      this.$router.push({
        name: 'collection-item',
        params: {
          collectionId: this.schema.collectionId,
          itemKey: this.key
        }
      })
      // this.dialog = false
    }
  }
}
</script>
